<template>
	<div>
		<!-- 公共头部 -->
		<Header ></Header>
		
		<div class="section1">
			<template v-for="(item,index) in integralLogList" >
				<div class="item" :class="[item.type == 1 ? 'add' : '']" :key="index">
					<div class="content">
						<div class="title">{{item.case}}</div>
						<div class="desc">{{item.addtime}}</div>
					</div>
					<div class="num">{{item.num}} 积分</div>
				</div>
			</template>
		</div>
		<wd-pagination v-model="page" :total="total"></wd-pagination>
		<div class="blank_80"></div>
		
		<!-- 公共底部 -->
		<!-- <Footer></Footer> -->
	</div>
</template>

<script>
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				title: '积分日志',
				limit: 10,
				page: 1,
				total: 0,
				integralLogList: []
			}
		},
		watch: {
			page() {
				if(this.$route.path == '/integral/log'){
					this.getIntegralLog();
				}
			}
		},
		computed: {
			uid() {
				return this.$store.state.uid;
			}
		},
		activated() {
			this.getUserInfo();
		},
		deactivated() {
			this.page = 1;
			this.total = 0;
			this.integralLogList = [];
		},
		methods: {
			// 获取积分记录
			async getIntegralLog(){
				var limit = this.limit;
				var page = this.page;
				var uid = this.uid;
				var data = {
					limit, page, uid
				};
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/integral/integralLog', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('我的积分 积分记录：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					this.total = res.data.count;
					var integralLogList = res.data.lists;
					for(var i = 0; i < integralLogList.length; i++) {
						integralLogList[i]['addtime'] = this.utils.js_date_time(integralLogList[i]['addtime'],1);
						if(integralLogList[i]['num'].substr(0,1) == '+'){
							integralLogList[i]['type'] = 1;
						} else {
							integralLogList[i]['type'] = 2;
						}
					}
					this.integralLogList = integralLogList;
				} else {
					this.integralLogList = [];
				}
			},
			async getUserInfo(){
				if(!this.uid){
					const confirmResult = await this.$messageBox.confirm('您当前未登录，请登录后再进行操作！', '登录提醒').catch(error => error);
					if(confirmResult != 'confirm'){
								wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
						return ;
					}
							wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
				} else {
					this.getIntegralLog();
				}
			},
		},
	};
</script>

<style scoped>
	.section1{
		margin-top: 0.3rem;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		background: #ffffff;
		margin-bottom: 0.6rem;
	}
	.section1 .item{
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 1.6rem;
		padding: 0 0.3rem;
		border-top: 0.02rem solid #e7e7e7;
	}
	.section1 .item:first-child{
		border-top: none;
	}
	.section1 .item .content{
		padding-left: 0.96rem;
		background-image: url(../../../assets/images/icon71.png);
		background-position: left center;
		background-repeat: no-repeat;
		background-size: 0.8rem 0.8rem;
	}
	.section1 .item.add .content{
		background-image: url(../../../assets/images/icon70.png);
	}
	.section1 .item .content .title{
		font-size: 0.32rem;
		color: #333333;
		line-height: 0.4rem;
	}
	.section1 .item .content .desc{
		font-size: 0.24rem;
		color: #969897;
		line-height: 0.4rem;
	}
	.section1 .item .num{
		font-size: 0.26rem;
		color: #333333;
	}
	.section1 .item.add .num{
		color: #ff6000;
	}
</style>